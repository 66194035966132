<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-accent"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
               <div class="form-body">
                   <div class="row">
                       <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.title.$error }">
                               <label for="title">Phương án vận chuyển</label>
                               <md-input name="title" v-model="entity.title"></md-input>
                               <span class="md-error" v-if="submitted && !$v.entity.title.required">Vui lòng nhập phương án vận chuyển</span>
                            </md-field>
                            <div class="radio-group">
                                <label>Trạng thái</label>
                                <div class="form-control ">
                                    <md-radio v-model="entity.isActive" :value="true">Hoạt động</md-radio>
                                    <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>
<script>
    import transportPlanService from '../../../api/transportPlanService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';

    export default ({
        metaInfo: {
            title: 'Thêm/Cập nhật phương án vận chuyển đơn hàng'
        },
        data() {
            return {
               title: '',
               id: 0,
               submitted: false,
               entity: { id: 0, title: '', type: 4, isActive: true },
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật phương án vận chuyển đơn hàng';
               this.getById();
            }
            else{
               this.title = 'Thêm mới phương án vận chuyển đơn hàng';
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                transportPlanService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                transportPlanService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/order-plan');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                transportPlanService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            back(){
                this.$router.push('/order-plan');
            }
        },
        validations: {
            entity: {
                title: { required }
            }
       }
     })

</script>
